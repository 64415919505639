import * as APIContracts from "@minime/contracts";
import { useQuery } from "@tanstack/react-query";

type UseFetchEmailParams = {
  userId: string;
  messageId: string;
};

export const useFetchEmail = ({ userId, messageId }: UseFetchEmailParams) =>
  useQuery<APIContracts.FetchEmailResponse>({
    queryKey: ["email", userId, messageId],
    queryFn: async () => {
      const headers = new Headers();
      headers.set("content-type", "application/json");
      const body: APIContracts.FetchEmailParams = {
        userId,
        messageId,
      };
      const res = await fetch("/api/v1/fetch_email", {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });
      return res.json();
    },
  });

type UseDraftReplyParams = {
  userId: string;
  messageId: string;
  command: string;
};

export const useDraftReply = (
  { userId, messageId, command }: UseDraftReplyParams,
  { enabled }: { enabled: boolean } = { enabled: true }
) =>
  useQuery<APIContracts.DraftWebReplyResponse | null>({
    queryKey: ["email", userId, messageId, command],
    queryFn: async () => {
      const headers = new Headers();
      headers.set("content-type", "application/json");
      const body: APIContracts.DraftWebReplyParams = {
        userId,
        messageId,
        command,
      };
      const res = await fetch("/api/v1/draft_web_reply", {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });
      return res.json();
    },
    enabled,
  });
