import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AutoGrowingTextArea } from "./components/AutoGrowingTextArea";
import SubmitButton from "./components/SubmitButton";
import { useDraftReply, useFetchEmail } from "./queries";

export default function Home() {
  const [draft, setDraft] = useState("");
  const [command, setCommand] = useState("");
  const [draftCommand, setDraftCommand] = useState("");
  const { userId, messageId } = useParams();
  const fetchEmail = useFetchEmail({ userId, messageId });
  const draftReply = useDraftReply(
    { userId, messageId, command: draftCommand },
    { enabled: !!draftCommand }
  );

  useEffect(() => {
    if (draftReply.isSuccess) {
      setDraft(draftReply.data.content);
    }
  }, [draftReply.isSuccess, draftReply.data]);

  const onDraft = async () => {
    setDraftCommand(command); // kicks off draft reply
  };

  const onSubmit = async () => {
    alert("FIXME: send the email");
  };

  const onCommandChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommand(e.currentTarget.value);
  };

  const onDraftChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDraft(e.currentTarget.value);
  };

  if (fetchEmail.isPending) return "Loading...";
  if (fetchEmail.error)
    return `An error has occurred: ${fetchEmail.error.message}`;

  return (
    <>
      <section>
        <div>Subject: {fetchEmail.data.email.subject}</div>
        <div>From: {fetchEmail.data.email.from}</div>
        <div>
          <pre
            style={{
              overflow: "scroll",
              border: "1px solid black",
              padding: "1rem",
              borderRadius: "1em",
              maxHeight: "20rem",
            }}
          >
            <code>{fetchEmail.data.email.body}</code>
          </pre>
        </div>
      </section>
      <section>
        <AutoGrowingTextArea
          onChange={onCommandChange}
          value={command}
          placeholder="Scaffold your reply here"
          style={{ borderRadius: "1em" }}
        />
      </section>
      <section>
        <div style={{ marginTop: "1rem", textAlign: "center" }}>
          <SubmitButton
            type="submit"
            onClick={onDraft}
            disabled={draftReply.isFetching}
          >
            {draftReply.isFetching && (
              <FontAwesomeIcon
                icon={faRefresh}
                spin
                style={{ marginRight: "0.5rem" }}
              />
            )}
            {draftReply.isFetching ? "Drafting..." : "Draft reply"}
          </SubmitButton>
        </div>
      </section>
      {draftReply.isSuccess && (
        <section style={{ marginTop: "1rem" }}>
          <AutoGrowingTextArea
            onChange={onDraftChange}
            value={draft}
            style={{ borderRadius: "1em" }}
          />
          <div style={{ marginTop: "1rem", textAlign: "center" }}>
            <SubmitButton type="submit" onClick={onSubmit}>
              Send
            </SubmitButton>
          </div>
        </section>
      )}
    </>
  );
}
