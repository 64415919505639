import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User, signInAnonymously } from "firebase/auth";
import { useState } from "react";

import { draftReply } from "./api_client";
import { AutoGrowingTextArea } from "./components/AutoGrowingTextArea";
import SubmitButton from "./components/SubmitButton";
import { auth } from "./firebase";

export default function Home() {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    let user: User | null = auth.currentUser;
    if (user === null) {
      const credential = await signInAnonymously(auth);
      user = credential.user;
    }
    const authToken = await user.getIdToken();
    const { content } = await draftReply({
      authToken,
      messageId: "FIXME",
    });
    setContent(content);
    setLoading(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.currentTarget.value);
  };

  return (
    <>
      <section>
        <AutoGrowingTextArea
          onChange={onChange}
          value={content}
          placeholder="Drafting..."
          style={{ borderRadius: "1em" }}
        />
        <div style={{ marginTop: "1rem", textAlign: "center" }}>
          <SubmitButton type="submit" onClick={onSubmit} disabled={loading}>
            {loading && (
              <FontAwesomeIcon
                icon={faRefresh}
                spin
                style={{ marginRight: "0.5rem" }}
              />
            )}
            {loading ? "Sending..." : "Send"}
          </SubmitButton>
        </div>
      </section>
    </>
  );
}
