import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: "5%" }}></div>
      <div style={{ flex: "90%", maxWidth: "50rem", paddingTop: "0.25rem" }}>
        <header>
          <h1 style={{ textAlign: "center" }}>MiniMe</h1>
        </header>
        <Outlet />
        <footer></footer>
      </div>
      <div style={{ flex: "5%" }}></div>
    </div>
  );
}
