import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { createGlobalStyle } from "styled-components";

import ErrorBoundary from "./ErrorBoundary";
import UserContext from "./contexts/UserContext";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`

body {
  font-family: ${theme.fontFamily.regular};
  font-size: ${theme.fontSize.base}px;
  background-color: ${theme.colors.greyLighter};
  color: ${theme.colors.black};
}

a, a:visited {
  color: ${theme.colors.black};
}

// fixes textarea zoom on iOS (must be > 16px)
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: inherit;
}
`;

const queryClient = new QueryClient();

export default function App() {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <GlobalStyle />
          <UserContext>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </UserContext>
        </Suspense>
      </ErrorBoundary>
    </RecoilRoot>
  );
}
