import { logEvent as _logEvent, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import compatApp from "firebase/compat/app";

export const app = initializeApp({
  apiKey: process.env.MY_FIREBASE_WEB_API_KEY,
  authDomain: "minime-400700.firebaseapp.com",
  projectId: "minime-400700",
  storageBucket: "minime-400700.appspot.com",
  messagingSenderId: "318379028380",
  appId: "1:318379028380:web:5c6cd7b7cb292e3e77e259",
  measurementId: "G-0W6WDTN4WN",
});

export const auth = getAuth(app);

const analytics = getAnalytics(app);

export function logEvent(name: string, params?: { [key: string]: any }) {
  _logEvent(analytics, name, params);
}

compatApp.initializeApp({
  apiKey: process.env.MY_FIREBASE_WEB_API_KEY,
  authDomain: "minime-400700.firebaseapp.com",
  projectId: "minime-400700",
  storageBucket: "minime-400700.appspot.com",
  messagingSenderId: "318379028380",
  appId: "1:318379028380:web:5c6cd7b7cb292e3e77e259",
  measurementId: "G-0W6WDTN4WN",
});

export { compatApp };
