import * as APIContracts from "@minime/contracts";

type DraftReplyParams = {
  userId: string;
  messageId: string;
};

type DraftReplyResult = { content: string };

export const draftReply = async ({
  userId,
  messageId,
}: DraftReplyParams): Promise<DraftReplyResult> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.DraftWebReplyParams = {
    userId,
    messageId,
  };
  const response = await fetch(`/api/v1/draft_web_reply`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  switch (response.status) {
    case 200:
      break;
    default:
      throw new Error(
        `invalid draft_web_reply response: ${response.status} ${response.statusText}`
      );
  }
  const res = (await response.json()) as APIContracts.DraftWebReplyResponse;
  return res;
};

type FetchEmailParams = {
  authToken: string;
  userId: string;
  messageId: string;
};

type FetchEmailResult = { email: APIContracts.Email };

export const fetchEmail = async ({
  userId,
  messageId,
}: FetchEmailParams): Promise<FetchEmailResult> => {
  const headers = new Headers();
  headers.set("content-type", "application/json");
  const body: APIContracts.FetchEmailParams = {
    userId,
    messageId,
  };
  const response = await fetch(`/api/v1/fetch_email`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
  switch (response.status) {
    case 200:
      break;
    default:
      throw new Error(
        `invalid fetch_email response: ${response.status} ${response.statusText}`
      );
  }
  const res = (await response.json()) as APIContracts.FetchEmailResponse;
  return res;
};
