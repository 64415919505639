import styled from "styled-components";

import theme from "../theme";

export default styled.button`
  background: ${theme.colors.black};
  border: none;
  outline: none;
  border-radius: 8px;
  font-weight: 700;
  font-size: ${theme.fontSize.med}px;
  color: ${theme.colors.white};
  min-width: 200px;
  min-height: 36px;
  outline: none;
  &:disabled {
    background: ${theme.colors.grey};
    color: ${theme.colors.greyLight};
    cursor: not-allowed;
  }
`;
